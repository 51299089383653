import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Feedback Hub API`}</h1>
    <p>{`The Feedback Hub is the place where editors find additional information related to the currently opened content. It
provides built in content validation (issues and warnings) and a comment function for editors.`}</p>
    <p>{`Additional feedback types for keyword and text analysis can be integrated via the Feedback Hub API. Specific Feedback
providers can be used to send data for analytics to third party systems. The result can be visualised in Studio using the component library of the Feedback Hub API.`}</p>
    <p>{`CoreMedia offers various example integrations for the Feedback Hub, like Acrolinx which gives you feedback to your grammar,
spelling and more or Imagga which offers automatic tagging for images.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "449px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/02c976be3bc03a220959bf595e8935d5/b5f99/FeedbackHub_Keywords.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAB2HAAAdhwGP5fFlAAACqklEQVQ4y61UW2sTURDe3+IdoWih4JOvgiAK4kOf9EnxJ/gPtN7qBXyRin0QLRaxN60PvVksVbT4UpvWtptNNrvJbnb3bPaW3TT5ZGabqK2FNnbgy8yZM+c7M3NmI515OoFrg/O4OjiP7hezuPzqE47fHcHBW0M41DOEwz2pbtpHbg9vA/mP3RnByd4xSB33R3H6yQd0PXyPE71jrDsfvEPHvVFeUyDF0Pro5uGdiGlf6no0ju6Xczjf/xGnHo8zzj2bwtm+SVzsn+ELrgzMcQXX33zGhefTOHDzbSvrrZDGFpYwMPsNI18XMZ1RMPEjy5hcSjX5FvMlrGgG1nQTmYKBmUwOU0sKpjJKqpvIKJDskg55JQNTU4FaDCRVNOKI0bSTKEQchaiGAULfQy30d4TkByGE66JsWTDLZRimibJlM0pGaluWzXsUEycJktoGklptCzZQ8YjQ92GaJgqFAspEaBiwbZuh63rLVyqV2K7X6yBpNBrYKmEYpoQUrGkaawIR0eZupUnOhJ7nYXV1FcvLy5DX19nOZhWoah5ZWYaiKMjKWeTzeb5UluXUn019SZL8nSH96JqGXC4H4ThcPpVLFzmOA+EKCCHg+T6CIEh9QsB1XY5ptqBFWK1WQaBNkho1OEn+2aNdl6yqKqdP5ZGmB6q3S+h7HkrFIj+EaRhMZtk2l082P5KmwbGdlo96WdR1Lp1GplZvYKPeQBCEkKIw4kOUJWm6JY5jJHHMPYui6LcvSdhH62oUsW/b2ESbG5VKhYP3Kt9VgdcLKqZ/mvD8ICVsR5odvjG8iM6eSVzq+wJTeOmjEGk7oOkgkrWiA82qcJUSzZVlWa3PbS+gc65wEFQEKsLhGZVoQP8H9MfiCBdCpGuJnn4/wRk2P6W2s9w8z4R/LvaD9Bc4uUANGInJWgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/02c976be3bc03a220959bf595e8935d5/e93cc/FeedbackHub_Keywords.webp 300w", "/static/02c976be3bc03a220959bf595e8935d5/c664f/FeedbackHub_Keywords.webp 449w"],
              "sizes": "(max-width: 449px) 100vw, 449px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/02c976be3bc03a220959bf595e8935d5/eed55/FeedbackHub_Keywords.png 300w", "/static/02c976be3bc03a220959bf595e8935d5/b5f99/FeedbackHub_Keywords.png 449w"],
              "sizes": "(max-width: 449px) 100vw, 449px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/02c976be3bc03a220959bf595e8935d5/b5f99/FeedbackHub_Keywords.png",
              "alt": "FeedbackHub Keywords",
              "title": "FeedbackHub Keywords",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Feedback Hub in Studio with Imagga integration`}</em></p>
    <h2>{`Use Cases`}</h2>
    <ul>
      <li parentName="ul">{`Integrating third-party feedback provider into Studio`}</li>
      <li parentName="ul">{`Visualize the feedback in Studio`}</li>
    </ul>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Learn the basics about how to connect a third-party system with Studio in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/current/webhelp/studio-developer-en/content/Feedback_Hub.html"
        }}>{`Studio Developer Manual`}</a></li>
      <li parentName="ul">{`Learn how to write a Feedback Hub adapter in the `}<a parentName="li" {...{
          "href": "https://github.com/CoreMedia/feedback-hub-adapter-tutorial"
        }}>{`Feedback Hub Adapter Tutorial`}</a></li>
      <li parentName="ul">{`As an example, study the `}<a parentName="li" {...{
          "href": "https://github.com/CoreMedia/feedback-hub-adapter-acrolinx"
        }}>{`Feedback Hub Adapter for Acrolinx`}</a>{` which integrates spellchecks, grammar checks and
more using Acrolinx.`}</li>
      <li parentName="ul">{`As an example, study the `}<a parentName="li" {...{
          "href": "https://github.com/CoreMedia/feedback-hub-adapter-siteimprove"
        }}>{`Feedback Hub Adapter for Siteimprove`}</a>{` which rates your site.`}</li>
      <li parentName="ul">{`As an example, study the `}<a parentName="li" {...{
          "href": "https://github.com/CoreMedia/feedback-hub-adapter-imagga"
        }}>{`Feedback Hub Adapter for Imagga`}</a>{` which offers automatic KI based
tagging of images.`}</li>
      <li parentName="ul">{`As an example, study the `}<a parentName="li" {...{
          "href": "https://github.com/CoreMedia/feedback-hub-adapter-searchmetrics"
        }}>{`Feedback Hub Adapter for Sitemetrics`}</a>{` which
integrates Searchmetrics briefings into the CoreMedia`}</li>
      <li parentName="ul">{`Find the API documentation in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/artifacts/2406.0.3/javadoc/studio-server/index.html"
        }}>{`Studio Server API`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      